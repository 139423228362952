import React from 'react';
import {Link} from 'gatsby'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import Home from '@material-ui/icons/Home'
import {AppBar, Toolbar} from '@material-ui/core'
import ImgLogo from '../images/logo-cipolla.webp'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      direction: 'rtl',
    },    
    appBar: {
        backgroundColor: '#fbfbfb',
        color: '#000',
        minHeight: 65
    },
    button:{
      color: '#000',
      fontSize: '12pt',
      fontWeight: 600,
      lineHeight: '1rem',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      marginRight: 'auto',
      width: 115,                
      '&:hover': {
        textDecoration: 'underline',
      }
    },    
    logoContainer:{
      position: 'absolute',
      zIndex: 1
    },
    logoImg: {
      width: 65,
      marginRight: 30,
      marginBottom: 0
    },
    titleDiv: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    mobileHome:{
      display: 'none'
    },
    descHome:{
      display: 'flex'
    },
    phoneTitle: {
      fontWeight: 'bold', marginRight: 30
    },
    divTitleWrapper:{
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    },
    '@media (max-width: 420px)':{
      root:{
        display: 'block',
      },
      mobileHome:{
        display: 'block'
      },
      descHome:{
        display: 'none'
      },
      titleDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: 'auto',
        '& span':{
          fontSize: '14px',
        },
             
      },
      phoneTitle:{
        marginRight: 0
      },
      logoImg: {
        width: 65,
        marginRight: 5,
        marginBottom: 0
      },  
    }   
  }),
);

 const HeaderEdit: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <div className={classes.logoContainer}>
          <Link to='/'>
            <img src={ImgLogo} alt="" className={classes.logoImg}/>
          </Link>                  
        </div> 
        <Toolbar className="container">
            <div className={classes.divTitleWrapper}>
              <div className={classes.titleDiv}>
                  <span> בית עובד 8, תל אביב </span>
                  <span className={classes.phoneTitle}>058-7-267676</span>
              </div>
            </div>
           <Link to='/products' className={classes.button+ ' '+ classes.descHome}>
             עמוד הבית
             <KeyboardArrowLeft/>
           </Link>
           <Link to='/products' className={classes.mobileHome}>
             <Home style={{color:'#159615'}}/>
           </Link>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default HeaderEdit
